import React from 'react';

const App: React.FC = () => {
  return (
    <div>
      <h1>Now you've been bamboozled with React! &#128520;</h1>
    </div>
  );
}

export default App;
